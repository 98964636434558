import { addHistoryListener, getLocationSnapshot } from 'packages/history';
import {
  createElement,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { BaseUrlContext, HistoryContext, LocationContext } from './contexts';

/**
 * Configures the context in order to use a router.
 * You MUST specify a `<RouterProvider />` parent in order to `useRoutes`
 */
export const RouterProvider: FC<{
  /**
   * The base URL to use for this router. Defaults to the document.baseURI
   * which might only be set appropriately if using a `<base href>  tag in
   * the document
   *
   * The default does have the location origin removed to use relative paths
   *
   * @default document.baseURI
   */
  baseUrl?: string;

  /**
   * The location object to use to determine the current URL
   * @default window.location
   */
  browserLocation?: Location;

  /**
   * The history object to use to navigate around using HTML5 routing
   * without page reload
   * @default window.history
   */
  browserHistory?: History;
}> = ({
  browserHistory = history,
  browserLocation = location,
  baseUrl = document.baseURI.replace(browserLocation.origin, ''),
  children
}) => {
  // Strip out the base url trailing slash if any
  baseUrl = useMemo(
    () =>
      baseUrl.slice(0, baseUrl[baseUrl.length - 1] === '/' ? -1 : undefined),
    [baseUrl]
  );
  const [locationSnapshot, setLocationSnapshot] = useState(() =>
    getLocationSnapshot(browserLocation)
  );
  const lastHrefRef = useRef<string>();
  lastHrefRef.current = locationSnapshot.href;

  const updateLocationSnapshot = useCallback(
    () =>
      // Only update the location if the href changed.
      lastHrefRef.current !== browserLocation.href &&
      setLocationSnapshot(getLocationSnapshot(browserLocation)),
    [browserLocation]
  );

  // Listen for any updates to the history and re-generate the location snapshot
  useEffect(() => {
    // We have to update location again in case it changed during initial
    // render before we started listening
    updateLocationSnapshot();
    return addHistoryListener(browserHistory, updateLocationSnapshot);
  }, [browserHistory, updateLocationSnapshot]);

  return (
    <BaseUrlContext.Provider value={baseUrl}>
      <HistoryContext.Provider value={browserHistory}>
        <LocationContext.Provider value={locationSnapshot}>
          {children}
        </LocationContext.Provider>
      </HistoryContext.Provider>
    </BaseUrlContext.Provider>
  );
};
