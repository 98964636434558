import { Linkable, LinkableWithParams } from 'packages/links';
import { MouseEvent, useCallback } from 'react';
import { useBaseUrl, useHistory } from './contexts';

export interface Anchor {
  href: string;
  onClick: (event?: MouseEvent) => void;
}

export interface UseAnchor {
  (link: Linkable): Anchor;
}
export interface UseAnchor {
  <T extends LinkableWithParams<any>>(
    link: T,
    params: T extends LinkableWithParams<infer P> ? P : never
  ): Anchor;
}

export const useAnchor: UseAnchor = (
  link: LinkableWithParams<any>,
  params: any = {}
): Anchor => {
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const href = baseUrl + link.url(params);
  const onClick = useCallback(
    (event?: MouseEvent) => {
      // if the user is holding cmd/ctrl, exit early and allow default behavior
      if (event?.metaKey || event?.ctrlKey) return;
      event && event.preventDefault && event.preventDefault();
      history.pushState(null, '', href);
    },
    [history, href]
  );

  return { href, onClick };
};
