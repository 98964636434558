import { ReactElement, useMemo } from 'react';
import { useBaseUrl, useLocation } from './contexts';
import { Route } from './create-route';

/**
 * A Router.
 */
export const useRoutes = (routes: Route[]): ReactElement | void => {
  const location = useLocation();
  const baseUrl = useBaseUrl();
  const render = useMemo(() => {
    for (const route of routes) {
      const result = route(location, baseUrl);
      if (result) return result;
    }
  }, [baseUrl, location, ...routes]); // eslint-disable-line react-hooks/exhaustive-deps

  // If we have a render func, return the result of it as a ReactNode to render
  if (render) return render();
};
