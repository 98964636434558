import { LocationSnapshot } from 'packages/history';
import { createContext, useContext } from 'react';

export const HistoryContext = createContext<History>(null as any);
export const LocationContext = createContext<LocationSnapshot>(null as any);
export const BaseUrlContext = createContext<string>(null as any);

export const useHistory = () => useContext(HistoryContext);
export const useLocation = () => useContext(LocationContext);
export const useBaseUrl = () => useContext(BaseUrlContext);
