import { Matchable } from 'packages/links';
import { useMemo } from 'react';
import { useBaseUrl, useLocation } from './contexts';
import { removeBaseUrl } from './remove-base-url';

export interface RouteActiveOptions {
  /**
   * Should this link match exactly the given route, or just the start of it?
   * @default true
   */
  exact?: boolean;
}

/**
 * useRouteActive to determine when a given link is currently matched against the current url.
 * If you need to access the matched params, use the standard useRoutes instead
 */
export const useRouteActive = (
  link: Matchable<any>,
  { exact = true }: RouteActiveOptions = {}
) => {
  const baseUrl = useBaseUrl();
  const { pathname } = useLocation();
  return useMemo(() => !!link.match(removeBaseUrl(pathname, baseUrl), exact), [
    link,
    pathname,
    baseUrl,
    exact
  ]);
};
