import { FC, useEffect } from 'react';
import { useBaseUrl, useHistory } from './contexts';

export const Redirect: FC<{
  /**
   * URL To where you want to redirect.
   * Should be generated using `Link.url(params)`
   * See `packages/links` for details about Link.
   */
  to: string;
  /**
   * Should this pushState to the current history stack
   * by pushing on this new URL? Or should we retain the default
   * behavior of replacing the current url on the history stack?
   *
   * @default false
   */
  pushState?: boolean;
}> = ({ to, pushState = false }) => {
  const baseUrl = useBaseUrl();
  const history = useHistory();
  useEffect(() => {
    history[pushState ? 'pushState' : 'replaceState'](null, '', baseUrl + to);
  }, [baseUrl, history, to, pushState]);
  return null;
};
