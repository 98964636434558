import { LocationSnapshot } from 'packages/history';
import { AnyLink, ParamsOfLink } from 'packages/links';
import { ReactElement } from 'react';
import { removeBaseUrl } from './remove-base-url';

export interface Route {
  (location: LocationSnapshot, baseUrl: string): void | (() => ReactElement);
}

export interface RouteOptions {
  /**
   * Should this link match exactly the given route, or just the start of it?
   * @default true
   */
  exact?: boolean;
}

/**
 * Given a link, call the the render function with any matched params to render a route.
 *
 * Optionally specify to match the start of a link for fuzzy matching
 */
export const createRoute = <L extends AnyLink<any>>(
  link: L,
  render: (params: ParamsOfLink<L>) => ReactElement,
  { exact = true }: RouteOptions = {}
): Route => ({ pathname }, baseUrl) => {
  const match = link.match(removeBaseUrl(pathname, baseUrl), exact);
  if (match) return () => render(match as any);
  // return undefined in a "no-match" case
};
